import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion} from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainerPersonal from "../components/StockGainerPersonal";
import ChartTwoFull from "../components/ChartTwoFull";
import ChartExpert from "../components/ChartExpert";
import ChartBandLight from "../components/ChartBandLight";
import ChartThreeLight from "../components/ChartThreeLight";

export const LiveFXPageTemplate = ({ image, title, heading, subheading, mainpitch, description, intro }) => (
  <div>
    <Helmet>
      <title>Swiftfox | FAQ</title>
    </Helmet>

   
    <div className="callout-section dark-bg white-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items">
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                <span>Live FX Rates</span>
              </motion.h2>
            )}
          </InView>
          <InView threshold={0.1}>
            {({ ref, inView }) => (
              <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="hero-chart sizefixernew">
                <ChartTwoFull />
              </motion.div>
            )}
          </InView>
        </div>
      </div>
    </div>
    <div className="callout-section callout-bg dark-text" id="about">
      <div className="callout-main">
        <div className="callout-main-content">
          <div className="section-item white-text">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  Your expert currency exchange broker
                </motion.h2>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.3}>
              {({ ref, inView }) => (
                <motion.p ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  There are countless ways to transfer money abroad. The most important consideration is the timing and mode of a transaction. When you exchange currencies with Swiftfox, we help you find that sweet spot, reducing your risk and maximising returns.
                </motion.p>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>
    <div className="hero-section dark-bg">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h2 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}>
                  <span>International payments, made easy.</span>
                </motion.h2>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4 ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}>
                  Swiftfox is here to help you understand the market and avoid making mistakes.
<br/><br/>
We understand the disruption and volatility of the FX markets and employ strategies that mitigate risk and maximise returns.

                </motion.h4>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 0.75, ease: "easeInOut" }} className="button-container">
                  <Link className="main-button" data-srcoll-to to="/open-an-account">
                  Check my rates
                  </Link>
                </motion.div>
              )}
            </InView>
          </div>
          <div className="section-item">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 1, delay: 1.0, ease: "easeInOut" }} className="module-stack">
                  <div className="chart">
                    <ChartExpert />
                  </div>
                  <div className="bullet-container">
                    <div className="bullet">
                      <img alt="Fees" src="../img/cash.svg" />
                      <p>Fees &<br />Charges</p>
                    </div>
                    <div className="bullet">
                      <img alt="Tax" src="../img/tax.svg" />
                      <p>Tax<br />Clearance</p>
                    </div>
                    <div className="bullet">
                      <img alt="FX Dealers" src="../img/fx.svg" />
                      <p> Access of<br />FX Dealers</p>
                    </div>
                  </div>
                </motion.div>
              )}
            </InView>
          </div>
        </div>
      </div>
      <div className="hero-banner-light">
        <InView threshold={0.1}>
          {({ ref, inView }) => (
            <motion.div ref={ref} initial={{ opacity: 0 }} animate={inView ? { opacity: 1 } : { opacity: 1 }} transition={{ duration: 0.4, delay: 1.25, ease: "easeInOut" }} className="hero-banner-ticker">
                <ChartBandLight />
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    



  </div>
);

LiveFXPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const FAQPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <LiveFXPageTemplate image={frontmatter.image} title={frontmatter.title} heading={frontmatter.heading} subheading={frontmatter.subheading} mainpitch={frontmatter.mainpitch} description={frontmatter.description} intro={frontmatter.intro} />
    </Layout>
  );
};

FAQPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default FAQPage;

export const pageQuery = graphql`
  query LiveFXPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
